<script>
import Button from 'primevue/button'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'

import { deleteSpend, loadAdSpends } from '../api/expenses.api'
import NewSpendModal from '../components/Expenses/NewSpendModal.vue'

export default {
  name: 'SpendsView',
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    NewSpendModal,
  },
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data: () => ({
    adSpends: [],
    showCreateEditModal: false,
    isLoading: false,
    spendId: null,
    showDeleteModal: false,
    queryOptions: {
      page: 1,
      total: 0,
      limit: 1,
      currentPage: 1,
      lastPage: 1,
    },
  }),
  watch: {
    idWharhouse: async function (oldVal, newVal) {
      await this.fetchSpends()
    },
  },

  methods: {
    async fetchSpends() {
      this.isLoading = true
      let filters = { country : this.idWharhouse.country}
      const res = await loadAdSpends(filters)
      this.adSpends = res.data
      this.isLoading = false

    },
    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.currentUser.role.permissions;

      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];

        if (model === modelToCheck) {
          return access[accessToCheck];
        }
      }
    },

    handleEdit(id) {
      this.spendId = id
      this.showCreateEditModal = true
    },

    handleShowModal(spendId) {
      this.spendId = spendId
      this.showDeleteModal = true
    },

    handleToggle(value) {
      this.showCreateEditModal = value
      if (!value) {
        this.spendId = null
      }
      console.log(value)
    },

    formateCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    },

    handleDelete(id) {
      deleteSpend(id)
        .then(() => {
          this.showDeleteModal = false
          this.$toast.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Record deleted',
            life: 3000,
          })
          this.spendId = null
          this.fetchSpends()
        })
        .catch(error => {
          console.log(error)
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'an Error has occurred while deleting the expense',
            life: 3000,
          })
        })
    },
  },

  async mounted() {
    await this.fetchSpends()
  },
}
</script>

<template>
  <div class="p-6">
    <Dialog header="Delete Confirmation" :visible.sync="showDeleteModal" :modal="true">
      <div class="confirmation-content flex items-center">
        <i class="pi pi-exclamation-triangle mr-3 text-red-600" style="font-size: 2rem" />
        <span>Are you sure you want to delete this expense?</span>
      </div>
      <template #footer>
        <Button label="Cancel" @click="showDeleteModal = false" class="p-button-text" />
        <Button label="Confirm" @click="handleDelete(spendId)" class="p-button-text p-button-danger" />
      </template>
    </Dialog>
    <div v-if="getAccess('spends', 'create')"  class="flex items-center justify-between mb-8">
      <h1 class="text-xl font-semibold">Spends</h1>

      <NewSpendModal @spends-refetch="fetchSpends" :spendId="spendId" @update:visible="handleToggle"
        :visible.sync="showCreateEditModal" :idWharhouse="idWharhouse" />
    </div>
    <DataTable :value="adSpends" paginator responsiveLayout="scroll" dataKey="_id" :loading="isLoading" :rows="10"
      :rowsPerPageOptions="[10, 20, 50]"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <Column field="spendAt" header="Spend At" style="flex: 0 0 4rem">
        <template #body="{ data }">
          <span class="text-sm font-semibold">
            {{
              new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              }).format(new Date(data.spendAt))
            }}
          </span>
        </template>
      </Column>

      <Column field="amountSpent" header="Ads Amount Spent">
        <template #body="{ data }">
          {{
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(data.amountSpent)
          }}
        </template>
      </Column>

      <Column field="platform" header="Platform">
        <template #body="{ data }">
          <img :src="`https://www.google.com/s2/favicons?sz=64&domain_url=${data.platform.toLowerCase()}.com`"
            :alt="data.platform" class="w-6 h-6 mr-2" />
          {{ data.platform }}
        </template>
      </Column>
      <Column field="spend" header="Status">
        <template #body="{ data }">
          <span class="p-tag p-tag-success" v-if="data.spend">Spent</span>
          <span class="p-tag p-tag-warning" v-else>Not Spent</span>
        </template>
      </Column>
      <Column field="product.name" header="Product Name"></Column>
      <Column field="tag" header="Product Tag"></Column>
      <!-- <Column field="productCost" header="Product Cost">
        <template #body="{ data }">
          {{
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'uSD',
            }).format(data.productCost)
          }}
        </template>
      </Column> -->

      <Column field="AddedBy.fullName" header="Marketer Name">
        <!-- <template #body="{ data }">
          {{
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'uSD',
            }).format(data.productCost)
          }}
        </template> -->
      </Column>
      <Column header="Actions">
        <template #body="{ data }">
          <span class="flex gap-2">
            <Button v-if="getAccess('spends', 'update') " icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-sm"
              @click="handleEdit(data._id)" />
            <Button v-if="getAccess('spends', 'delete')"  icon="pi pi-times" class="p-button-text p-button-danger p-button-sm"
              @click="handleShowModal(data._id)" />
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>




<style lang="css">
.p-datatable-scrollable table td,
.p-datatable-scrollable table th {
  overflow-x: auto;
}

.p-datatable-scrollable-body {
  overflow-y: scroll;
}

.p-paginator>.p-paginator-current {
  margin-left: auto;
}
</style>
