<script>
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Vue2Datepicker from 'vue2-datepicker'
import { createSpend, loadSpend, updateSpend } from '../../api/expenses.api'
import { getProducts } from '../../api/products.api'

export default {
  name: 'NewSpendModal',
  components: {
    Sidebar,
    Button,
    InputNumber,
    Calendar,
    InputSwitch,
    Skeleton,
    vSelect,
    InputText,
    Vue2Datepicker,
  },

  props: {
    spendId: { type: String },
    idWharhouse: { type: Object },
    visible: { type: Boolean }
  },

  emits: ['spends-refetch', 'update:visible'],

  data() {
    return {
      payload: {
        productCost: 0,
        amountSpent: 0,
        spendAt: new Date(),
        isSpent: false,
        product: null,
        tag: null,
        platform: null,
        country: null,
        AddedBy: null
      },
      currentUser: null,
      selectedProduct: null,
      isLoading: false,
      products: [],
    }
  },

  watch: {
    idWharhouse: async function (oldVal, newVal) {
      this.payload.country = oldVal.country;
      const result = await getProducts({ 'details.warehouse': this.idWharhouse._id })
      this.products = result.content.results

    },
    async spendId() {
      if (this.spendId) {
        this.isLoading = true
        const response = await loadSpend(this.spendId)
        this.payload = {
          amountSpent: response.data.content.amountSpent,
          spendAt: new Date(response.data.content.spendAt),
          isSpent: response.data.content.spent,
          productCost: response.data.content.productCost,
          product: response.data.content.product,
          platform: response.data.content.platform,
          tag: response.data.content.tag,

        }
        this.selectedProduct = {
          label: response.data.content.product.name,
          value: response.data.content.product._id,
        }
        this.isLoading = false
      }
    },
  },

  emits: ['spends-refetch'],

  methods: {
    handleOpen() {
      this.$emit('update:visible', true)
    },

    handleClose() {
      this.payload = {
        amountSpent: 0,
        spendAt: new Date(),
        isSpent: false,
        product: null,
        productCost: 0,
        platform: null,
        tag: null,
        country: null,
        AddedBy: this.currentUser

      }
      this.selectedProduct = null
      this.$emit('update:visible', false)
    },

    handleSubmit() {
      const handleSuccess = () => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: this.spendId
            ? 'Spend updated successfully'
            : 'Spend created successfully',
          life: 3000,
        })
        this.payload = {
          amountSpent: 0,
          spendAt: new Date(),
          isSpent: false,
          product: null,
          productCost: 0,
          platform: null,
          tag: null,
          country: null,
          AddedBy: this.currentUser



        }
        this.$emit('spends-refetch')
        this.$emit('update:visible', false)
      }

      const handleError = () => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: this.spendId
            ? 'Spend update failed'
            : 'Spend creation failed',
          life: 3000,
        })
        this.$emit('update:visible', false)
      }

      const payload = {
        ...(this.spendId && { _id: this.spendId }),
        spendAt: new Date(this.payload.spendAt),
        amountSpent: this.payload.amountSpent,
        spend: this.payload.spendAt ? true : false,
        productCost: this.payload.productCost,
        product: this.selectedProduct?.value,
        platform: this.payload.platform,
        tag: this.payload.tag.value,
        country: this.payload.country,
        AddedBy: this.currentUser


      }

      const spendAction = this.spendId ? updateSpend : createSpend

      spendAction(payload).then(handleSuccess).catch(handleError)
    },
  },

  computed: {
    title() {
      return this.spendId ? 'Edit Spend' : 'New Spend'
    },

    updateButtonLabel() {
      return this.spendId ? 'Update' : 'Create'
    },

    formattedProducts() {
      return this.products.map(product => {
        return {
          label: product.name,
          value: product._id,
        }
      })
    },

    productTags() {
      return this.products
        .filter(item => item._id === this.selectedProduct?.value)[0]
        ?.tags?.map(tag => {
          return {
            label: tag,
            value: tag,
          }
        })
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
        this.payload = {
          productCost: 0,
          amountSpent: 0,
          spendAt: new Date(),
          isSpent: false,
          product: null,
          tag: null,
          platform: null,
          country: null

        }
      },
    },
  },

  async mounted() {
    const me = await this.$server.me('users');
    if (me) {
      this.currentUser = me.content._id

    }
    const result = await getProducts({ 'details.warehouse': this.idWharhouse._id })
    this.products = result.content.results
    this.payload.country = this.idWharhouse.country
    console.log('idWharhouse', this.idWharhouse)

  },
}
</script>

<template>
  <div>
    <Button @click="handleOpen">New Spend</Button>
    <Sidebar :visible.sync="show" :baseZIndex="1000" :modal="true" position="right" class="p-sidebar-md">
      <h3 class="mb-8 text-2xl font-semibold">{{ title }}</h3>

      <form class="p-fluid grid space-y-6" v-if="isLoading && spendId">
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
      </form>
      <form class="p-fluid grid space-y-6" v-else>
        <div class="flex flex-col" v-if="visible">
          <label for="spent-at" class="font-semibold mb-2">Spent at</label>
          <Calendar id="spend-at" v-model="payload.spendAt" :yearNavigator="true" required />

        </div>
        <div class="field col-12 md:col-4">
          <label for="platform" class="font-semibold mb-2">Platform</label>
          <vSelect v-model="payload.platform" class="border p-2" :options="[
            'Google',
            'Facebook',
            'Instagram',
            'Snapchat',
            'Youtube',
          ]"></vSelect>
        </div>
        <div class="field col-12 md:col-3">
          <label for="amount-spent" class="font-semibold" mb-2>Amount Spent</label>
          <InputNumber id="amount-spent" v-model="payload.amountSpent" required mode="currency" currency="USD" />
        </div>

        <div class="field col-12 md:col-3">
          <label for="product-cost" class="font-semibold" mb-2>Product</label>
          <vSelect id="product-cost" label="label" v-model="selectedProduct" class="border p-2"
            :options="formattedProducts"></vSelect>
        </div>
        <div class="field col-12 md:col-3" v-if="selectedProduct?.value && productTags.length > 0">
          <label for="product-tag" class="font-semibold" mb-2>Product Tag</label>
          <vSelect id="product-tag" label="label" v-model="payload.tag" class="border p-2" :options="productTags">
          </vSelect>
        </div>
        <!-- <div class="field col-12 md:col-3">
          <label for="product-cost" class="font-semibold mb-2">Product Cost</label>
          <InputNumber id="product-cost" mode="currency" currency="USD" required v-model="payload.productCost" />
        </div> -->
      </form>
      <div class="flex justify-start mt-8 gap-4">
        <Button :label="updateButtonLabel" class="p-button-sm" type="submit" @click="handleSubmit" />
        <Button label="Cancel" class="p-button-outlined p-button-sm" @click="handleClose" />
      </div>
    </Sidebar>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100%;
}

.mx-input {
  height: 48px;
  width: 100%;
}
</style>
