var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-6"
  }, [_c('Dialog', {
    attrs: {
      "header": "Delete Confirmation",
      "visible": _vm.showDeleteModal,
      "modal": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showDeleteModal = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('Button', {
          staticClass: "p-button-text",
          attrs: {
            "label": "Cancel"
          },
          on: {
            "click": function click($event) {
              _vm.showDeleteModal = false;
            }
          }
        }), _c('Button', {
          staticClass: "p-button-text p-button-danger",
          attrs: {
            "label": "Confirm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleDelete(_vm.spendId);
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "confirmation-content flex items-center"
  }, [_c('i', {
    staticClass: "pi pi-exclamation-triangle mr-3 text-red-600",
    staticStyle: {
      "font-size": "2rem"
    }
  }), _c('span', [_vm._v("Are you sure you want to delete this expense?")])])]), _vm.getAccess('spends', 'create') ? _c('div', {
    staticClass: "flex items-center justify-between mb-8"
  }, [_c('h1', {
    staticClass: "text-xl font-semibold"
  }, [_vm._v("Spends")]), _c('NewSpendModal', {
    attrs: {
      "spendId": _vm.spendId,
      "visible": _vm.showCreateEditModal,
      "idWharhouse": _vm.idWharhouse
    },
    on: {
      "spends-refetch": _vm.fetchSpends,
      "update:visible": [_vm.handleToggle, function ($event) {
        _vm.showCreateEditModal = $event;
      }]
    }
  })], 1) : _vm._e(), _c('DataTable', {
    attrs: {
      "value": _vm.adSpends,
      "paginator": "",
      "responsiveLayout": "scroll",
      "dataKey": "_id",
      "loading": _vm.isLoading,
      "rows": 10,
      "rowsPerPageOptions": [10, 20, 50],
      "paginatorTemplate": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "currentPageReportTemplate": "Showing {first} to {last} of {totalRecords} entries"
    }
  }, [_c('Column', {
    staticStyle: {
      "flex": "0 0 4rem"
    },
    attrs: {
      "field": "spendAt",
      "header": "Spend At"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [_c('span', {
          staticClass: "text-sm font-semibold"
        }, [_vm._v(" " + _vm._s(new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }).format(new Date(data.spendAt))) + " ")])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "amountSpent",
      "header": "Ads Amount Spent"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref2) {
        var data = _ref2.data;
        return [_vm._v(" " + _vm._s(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(data.amountSpent)) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "platform",
      "header": "Platform"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref3) {
        var data = _ref3.data;
        return [_c('img', {
          staticClass: "w-6 h-6 mr-2",
          attrs: {
            "src": "https://www.google.com/s2/favicons?sz=64&domain_url=".concat(data.platform.toLowerCase(), ".com"),
            "alt": data.platform
          }
        }), _vm._v(" " + _vm._s(data.platform) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "spend",
      "header": "Status"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref4) {
        var data = _ref4.data;
        return [data.spend ? _c('span', {
          staticClass: "p-tag p-tag-success"
        }, [_vm._v("Spent")]) : _c('span', {
          staticClass: "p-tag p-tag-warning"
        }, [_vm._v("Not Spent")])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "product.name",
      "header": "Product Name"
    }
  }), _c('Column', {
    attrs: {
      "field": "tag",
      "header": "Product Tag"
    }
  }), _c('Column', {
    attrs: {
      "field": "AddedBy.fullName",
      "header": "Marketer Name"
    }
  }), _c('Column', {
    attrs: {
      "header": "Actions"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref5) {
        var data = _ref5.data;
        return [_c('span', {
          staticClass: "flex gap-2"
        }, [_vm.getAccess('spends', 'update') ? _c('Button', {
          staticClass: "p-button-rounded p-button-text p-button-sm",
          attrs: {
            "icon": "pi pi-pencil"
          },
          on: {
            "click": function click($event) {
              return _vm.handleEdit(data._id);
            }
          }
        }) : _vm._e(), _vm.getAccess('spends', 'delete') ? _c('Button', {
          staticClass: "p-button-text p-button-danger p-button-sm",
          attrs: {
            "icon": "pi pi-times"
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModal(data._id);
            }
          }
        }) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }